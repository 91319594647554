import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/filters/Countries';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/countryStatusChange';
// import Popup1 from '../components/popups/CountryPopular';
import PriorityUpdatePopup from '../components/popups/PriorityUpdatePopup';
import NameUpdatePopup from '../components/popups/NameUpdatePopup';
import CategoryDelete from '../components/popups/CategoryDelete';
import useResponsive from '../hooks/useResponsive';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function Countries() {
  const isMobile = useResponsive('down', 'sm');
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const [countryData, setCountryData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [countryCount, setCountryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(1);
  const [priorityUpdateIndex, setPriorityUpdateIndex] = useState();
  const [nameUpdateIndex, setNameUpdateIndex] = useState();
  const [deleteIndex, setDeleteIndex] = useState();

  const countrySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    isBlocked: Yup.string().required('Status is required'),
    // isPopular: Yup.string().required('Is popular is required'),
  });

  const handlePriorityChange = (index) => {
    setPriorityUpdateIndex(index);
  };

  const handleCountryNameChange = (index) => {
    setNameUpdateIndex(index);
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      isBlocked: 'false',
    },
    validationSchema: countrySchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/country/admin`, values);
        if (!res.data.isError) {
          closeAddUserModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getCountries(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };
  // const handleStatusChange1 = (event, item, index) => {
  //   console.log('item', item);
  //   if (`${item.isPopular}` === event.target.value) {
  //     return;
  //   }
  //   console.log(index);
  //   setSelectedIndex1(index);
  // };

  const closeAddUserModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleClose = (refresh = false, message = '') => {
    console.log(priorityUpdateIndex);
    setSelectedIndex();
    setSelectedIndex1();
    setPriorityUpdateIndex();
    setNameUpdateIndex();
    setDeleteIndex();
    if (refresh) {
      getCountries(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddUserModal = () => {
    setOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getCountries(temp);
  };

  const getCountries = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/country/admin/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setCountryCount(res.data.data.maxRecords);
          setCountryData(res.data.data.records);
          setIsLoading(false);
        }
        console.log(priorityUpdateIndex);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getCountriesWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/country/admin/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setCountryCount(res.data.data.maxRecords);
          setCountryData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getCountriesWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    // if (filter.isPopular.length) {
    //   temp.isPopular = filter.isPopular;
    // }

    setOptions(temp);
    setPages(0);
    getCountries(temp);
  };
  useEffect(() => {
    getCountries(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getCountries(temp);
  }, [size]);

  return (
    <>
      <Page title="Categories">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* Add country Dialog */}
            <Dialog open={open} onClose={closeAddUserModal} fullScreen={isMobile}>
              <DialogTitle>Add Category</DialogTitle>
              <Grid container paddingBottom={'10px'} Width="500px">
                <DialogContent paddingTop="20px">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Stack direction={'column'} spacing={1} width={!isMobile ? '500px' : '100%'}>
                          <Stack direction="row" justifyContent="space-between">
                            <TextField
                              type="text"
                              label="Name"
                              fullWidth
                              value={values.name}
                              {...getFieldProps('name')}
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                            />
                          </Stack>
                          <Stack direction="row" justifyContent="space-between" spacing={1}>
                            <TextField
                              select
                              label="Status"
                              value={values.isBlocked}
                              fullWidth
                              {...getFieldProps('isBlocked')}
                              error={Boolean(touched.isBlocked && errors.isBlocked)}
                              helperText={touched.isBlocked && errors.isBlocked}
                            >
                              <MenuItem value={'false'}>Active</MenuItem>
                              <MenuItem value={'true'}>Blocked</MenuItem>
                            </TextField>
                            {/* <TextField
                              select
                              label="Is popular"
                              value={values.isPopular}
                              fullWidth
                              {...getFieldProps('isPopular')}
                              error={Boolean(touched.isPopular && errors.isPopular)}
                              helperText={touched.isPopular && errors.isPopular}
                            >
                              <MenuItem value={'false'}>No</MenuItem>
                              <MenuItem value={'true'}>Yes</MenuItem>
                            </TextField> */}
                          </Stack>
                        </Stack>
                      </Grid>
                      <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                        <Button onClick={closeAddUserModal}>Cancel</Button>
                        <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </DialogContent>
              </Grid>
            </Dialog>
            {/* Add country dialog end */}
            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Categories
                </Typography>
              </Stack>
              <Card>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent="space-between"
                  alignItems={{ xs: 'start', sm: 'center' }}
                >
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search category..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                  {isSuperAdmin && (
                    <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center" width="100%">
                      <Button startIcon={<AddIcon />} variant="contained" onClick={openAddUserModal}>
                        Category
                      </Button>

                      <Filter applyFilters={applyFilters} />
                    </Stack>
                  )}
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Feeds</TableCell>
                          <TableCell>Priority</TableCell>
                          <TableCell>Category status</TableCell>
                          {isSuperAdmin && <TableCell>Action</TableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {countryData?.map((item, id) => (
                          <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#FFE2E2' } }}>
                            <TableCell>{pages * size + (id + 1)}</TableCell>
                            <TableCell>
                              <Tooltip title=" Click to Change Category name">
                                <Typography sx={{ cursor: 'pointer' }} onClick={() => handleCountryNameChange(id)}>
                                  {item.name}
                                </Typography>
                              </Tooltip>
                              {nameUpdateIndex === id && <NameUpdatePopup item={item} handleClose={handleClose} />}
                            </TableCell>
                            <TableCell>{item.count}</TableCell>
                            {isSuperAdmin ? (
                              <>
                                {item.name !== 'Home' ? (
                                  <TableCell sx={{ cursor: 'pointer' }}>
                                    <Tooltip title=" Click to Change Priority">
                                      <Typography onClick={() => handlePriorityChange(id)}>
                                        {item.priority ? item.priority : 'No Priority'}
                                      </Typography>
                                    </Tooltip>
                                    {priorityUpdateIndex === id && (
                                      <PriorityUpdatePopup item={item} handleClose={handleClose} />
                                    )}
                                  </TableCell>
                                ) : (
                                  <TableCell>{item.priority ? item.priority : 'No Priority'}</TableCell>
                                )}
                              </>
                            ) : (
                              <TableCell>{item.priority ? item.priority : 'No Priority'}</TableCell>
                            )}

                            {isSuperAdmin ? (
                              <>
                                <TableCell>
                                  {item?.name !== 'Home' ? (
                                    <FormControl sx={{ minWidth: 100 }}>
                                      <Select
                                        size="small"
                                        value={item.isBlocked}
                                        onChange={(e) => handleStatusChange(e, item, id)}
                                      >
                                        <MenuItem value="false">Active</MenuItem>
                                        <MenuItem value="true">Blocked</MenuItem>
                                      </Select>
                                      {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                                    </FormControl>
                                  ) : (
                                    <Typography>{item.isBlocked ? 'Blocked' : 'Active'}</Typography>
                                  )}
                                </TableCell>
                              </>
                            ) : (
                              <TableCell>{item.isBlocked ? 'Blocked' : 'Active'}</TableCell>
                            )}

                            {isSuperAdmin && (
                              <>
                                {item.name !== 'Home' ? (
                                  <TableCell>
                                    <IconButton onClick={(e) => handleDelete(id)}>
                                      <Iconify icon={'mdi:delete'} />
                                    </IconButton>
                                    {deleteIndex === id && <CategoryDelete item={item} handleClose={handleClose} />}
                                  </TableCell>
                                ) : (
                                  <TableCell>
                                    <IconButton disabled>
                                      <Iconify icon={'mdi:delete'} />
                                    </IconButton>
                                  </TableCell>
                                )}
                              </>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                  }}
                  component={'div'}
                  count={countryCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
