import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import useResponsive from '../../hooks/useResponsive';

const PriorityUpdatePopup = (props) => {
  const isMobile = useResponsive('down', 'sm');
  const [newPriority, setNewPriority] = useState(props.item.priority);

  const updatePriority = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/country/admin`, {
        id: props.item._id,
        priority: newPriority,
      })
      .then((res) => {
        props.handleClose(true, res.data.message);
      })
      .catch((err) => {
        props.handleClose(
          false,
          err && err.response && err.response.data && err.response.data.message
            ? err.data.response.message
            : err.message
        );
      });
  };
  const open = true;
  return (
    <>
      <Dialog onClose={props.handleClose} open={open} fullScreen={isMobile}>
        <DialogTitle>Priority Update</DialogTitle>
        <DialogContent>
          <DialogContentText>Update the priority for this category:</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Priority"
            type="number"
            fullWidth
            value={newPriority}
            onChange={(e) => setNewPriority(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleClose(false)}>Cancel</Button>
          <Button onClick={updatePriority}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PriorityUpdatePopup;
